function Privacy() {
    return(
        <div className="Terms">
        <div className="TermsHeadings">
            <div className="TermsHeading">Privacy Policy</div>
            <div className="TermsSubHeading">nicode networks - nicodenetworks.com</div>
        </div>
        <div className="TermsDate">LAST MODIFIED: FEBURARY 16, 2024</div>
        <div className="TermsContent">
        <p>
        <div class="TermsContentHeading">1. Information Collection</div>
        <p class="TermsContentInfo">
            When you visit our site, register, or interact with our services,<br></br> we may collect personal identification information,
            <br></br>such as your name and other relevant details, especially when using Keycloak for login.
        </p>
        </p>
            <p>
                <div class="TermsContentHeading">2. Usage of Keycloak</div>
                <p class="TermsContentInfo">
                    Keycloak is utilized for secure and streamlined user authentication.
                    <br></br> Any data collected during the authentication
                    process is handled securely and in accordance <br></br>with Keycloak's privacy practices.
                </p>
            </p>

            <p>
                <div class="TermsContentHeading">3. Non-personal Identification Information</div>
                <p class="TermsContentInfo">
                    We may also collect non-personal identification information, such as browser information,
                    <br></br> computer type, and technical
                    details related to your connection to our site.
                </p>
            </p>

            <p>
                <div class="TermsContentHeading">4. Use of Google Fonts</div>
                <p class="TermsContentInfo">
                    Our website uses Google Fonts to enhance the visual appeal of the content.
                    <br></br> Please be aware that Google Fonts may collect
                    data as specified in their privacy policy,<br></br> which you can review on the Google Fonts website.
                </p>
            </p>

            <p>
                <div class="TermsContentHeading">5. Spotify API and Resources</div>
                <p class="TermsContentInfo">
                    nicodenetworks.com employs the Spotify API for song searches and integration with Spotify resources.<br></br> When using these
                    features, your interactions may be subject to Spotify's privacy policy.<br></br> We encourage you to review Spotify's privacy
                    practices for a comprehensive understanding.
                </p>
            </p>

            <p>
                <div class="TermsContentHeading">6. Web Browser Cookies</div>
                <p class="TermsContentInfo">
                    Cookies may be used to enhance your user experience.<br></br> You can adjust your browser settings to refuse cookies; however,<br></br>
                    some parts of the site may not function optimally in such cases.
                </p>
            </p>

            <p>
                <div class="TermsContentHeading">7. How We Use Collected Information</div>
                <p class="TermsContentInfo">
                    <strong>7.1 Personal Information</strong><br></br>
                    We collect and use personal information for purposes such as improving customer service,<br></br> personalizing user experience,
                    and enhancing our site based on user feedback.
                </p>
                <p class="TermsContentInfo">
                    <strong>7.2 Non-Personal Information</strong><br></br>
                    Non-personal information may be used for analytical purposes, improving the site,<br></br> and for marketing and advertising endeavors.
                </p>
            </p>

            <p>
                <div class="TermsContentHeading">8. Protection of User Information</div>
                <p class="TermsContentInfo">
                    We employ secure data collection, storage, and processing practices to prevent unauthorized access,<br></br> disclosure, alteration,
                    <br></br>or destruction of personal information and other sensitive data.
                </p>
            </p>

            <p>
                <div class="TermsContentHeading">9. Sharing Personal Information</div>
                <p class="TermsContentInfo">
                    We do not sell, trade, or rent users' personal identification information.<br></br> However, generic aggregated demographic
                    information may be shared with trusted affiliates and partners.
                </p>
            </p>

            <p>
                <div class="TermsContentHeading">10. Third-party Websites</div>
                <p class="TermsContentInfo">
                    Our site may contain links to third-party websites, including Spotify and Google Fonts.<br></br> We are not responsible for the
                    privacy practices of these external sites <br></br>and recommend reviewing their respective privacy policies.
                </p>
            </p>

            <p>
                <div class="TermsContentHeading">11. Contact Information</div>
                <p class="TermsContentInfo">
                    If you have any questions or concerns regarding this privacy policy,<br></br> please contact us at <strong>info@nicodenetworks.com</strong>.
                </p>
            </p>
        </div>
        <footer className="TermsContentFooter">Copyright © 2024 Nicolas Pfeifer. All rights reserved.</footer>
    </div>
    )
}

export default Privacy;