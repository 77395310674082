import { useNavigate } from "react-router-dom";
import useIsAuth from "../hooks/useIsAuth";
import Keycloak from 'keycloak-js';

function InviteHandler(){
    const queryParameters = new URLSearchParams(window.location.search);
    const playlistId = queryParameters.get('pl');
    const navigate = useNavigate();
    useIsAuth()
    .then(response => {

    console.log(response);

    if((playlistId === '1DWeZYfgG7OZlyUFUiDai4' || playlistId === 'schulball24') && response === false) { 
        setTimeout(() =>
        navigate('/login?pl='+playlistId, {replace: true}), 300);
    } else {
        //redirect to join Pl Page
        return(
            <>
            Already logged in!
            </>
        );
    }
    })
}

export default InviteHandler;