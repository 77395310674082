import "./ended.css"

const EndedPage = () => {
    return(
        <div className="endedMain">
            <div className="endedImage">
                <img src="./404.svg" alt="ended" width="300px" height="300px"/>
            </div>
            <div className="endedContent">
                <div className="endedHeading">Voting beendet!</div>
                <div className="endedSubheading">{"Bis morgen..."}</div>
            </div>
        </div>
    )
}

export default EndedPage;