import { useEffect, useState, useRef } from "react";
import Keycloak from "keycloak-js";

const useIsAuth = async () => {
    // ARCHIVE checks if account is logged in
    const client = new Keycloak({
        url: process.env.REACT_APP_KEYCLOAK_URL,
        realm: process.env.REACT_APP_KEYCLOAK_REALM,
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
    });

    try{
        const isAuth = await client.init({
            pkceMethod: 'S256',
            checkLoginIframe: false
        });
        console.log(isAuth);
        return isAuth;
    } catch(e){
        console.log(e);
        return e;
    }
};

export default useIsAuth;