import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, InputGroup, FormControl, Button, Row, Card, DropdownButton, Alert} from "react-bootstrap";
import {Auth0} from '@auth0/auth0-react';
import "./inviteLogin.css"
import LoginButton from '../../../components/loginBtn';

function Login(){
    return (
        <div className='Login'>
            <Container className='loginWrapper '>
                <div className='loginFormHeading'>You've been invited to collaborate to</div>
                <Container className='loginWrapperMain'>
                    <div className='loginPgImgHelper'>
                        <img src='playlistCover.jpg' className='loginPgImage' width={125} height={125} alt='Playlist Preview Image'></img>
                    </div>
                    <div className='inviteMainTxt'>SpotifyAPI</div>
                    <div className='inviteButtonWrapper'>
                        <LoginButton></LoginButton>
                    </div>
                    <div className='loginMainWidgetDiff'>
                        <img src='loginPageDiff.svg' width={300}/>
                    </div>
                    <a href='/main?v=1' className='loginViewPlTxt'>view playlist</a>
                </Container>
            </Container>
        </div>
    )
}

export default Login;