import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import btnStyle from"./loginBtn.module.css"

const LoginBtn = () => {
  const { loginWithRedirect } = useAuth0();
  return <button onClick={(e) => {
    e.preventDefault();
    window.location.href='http://localhost:3000/main';
    }} className={btnStyle.loginBtn}>Login</button>;
};

export default LoginBtn;