import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const LazyImage = ({src, imgClassName, width, height, colorPrimary, colorSecondary, radius}) =>  {

    const [hasLoadedImg, setHasLoadedImg] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.onload =() => {
            setHasLoadedImg(true);
        }
        img.src = src;
    }, [src]);

    return (
        <>
            {!hasLoadedImg ? (
                <SkeletonTheme baseColor={colorSecondary} highlightColor={colorPrimary}>
                    <Skeleton box width={width} height={height} borderRadius={radius}/>
                </SkeletonTheme>
            ): (
                <img className={imgClassName} src={src} width={width} height={height} loading="lazy" alt='album cover'/>
            )}
        </>
    )
}

export default LazyImage;