import MainPlaylist from './MainPlaylist';
import useAuthReq from '../hooks/useAuthReq';

function Login(){

    //main page with required login
    const [isLogin, token, userID] = useAuthReq();
    if (isLogin) return(
        <MainPlaylist token={{token}} userID={{userID}}></MainPlaylist>
    )
    
}

export default Login;