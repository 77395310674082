import {motion, AnimatePresence} from 'framer-motion';
import { useEffect, useState } from 'react';
 
function DropdownItem(props) {

  const [width, setWidth] = useState(window.innerWidth);
  const [darkMode, setDarkMode] = useState(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

    return(
        <div>
        <AnimatePresence>
        {
        props.isVisible && (
          <motion.div className='userAvatarDropdownActiveWrapper' key="modal" initial={{ height: 0, opacity: 0 }} animate={{ 
            height: (width <= 768) ? "auto" : 200, opacity: 1, 
            transition:{type: "spring", bounce: .3, opacity: {delay: 0.025}, duration: .5}
          }} exit={{ height: 0, opacity: 0 }} transition={{duration: .5, type: "spring", bounce: .3}}>
            <div className='userAvatarDropdownActive'>
              <div className='DAHeading'>ACCOUNT</div>
              <div className='DAuserName'>{ "@" + props.username}</div>
              <img className='DAdivider' src='./divider2.svg'></img>
              <div className='DAcontent'>
                <span className='DAcontentItemWrapper'><a href='mailto:info@nicodenetworks.com?subject=[Schulball24] Problem Report' className='DAcontentItem'>report</a></span>
                <span className='DAcontentItemWrapper'><a href='/guidelines' className='DAcontentItem'>guidelines-ToS</a></span>
                <span className='DAcontentItemWrapper'><a href='/faq' className='DAcontentItem'>FAQ</a></span>
                <span className='DAcontentItemWrapper'><a href={`${process.env.REACT_APP_KEYCLOAK_URL}realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout/`} 
                  className='DAcontentItem'>log out</a></span>
              </div>
            </div>
          </motion.div>

          )
        }
         </AnimatePresence>
         </div>
    )
}

export default DropdownItem;