import "./NotFound.css";

function NotFound(){
    return (
        <div className="NotFound">
            <div className="NotFoundContent">
                <div className="NotFoundTitle">404</div>
                <div className="NotFoundSubtitle"><i>looks like this page might have been removed...</i>
                <br></br>report problems: <b>info@nicodenetworks.com</b></div>
            </div>
        </div>
    )
}

export default NotFound;