import "./legal.css"

function Vote() {
    return(
        <div className="Terms">
            <div className="TermsHeadings">
                <div className="TermsHeading">Guidelines & Terms</div>
                <div className="TermsSubHeading">nicode networks - nicodenetworks.com</div>
            </div>
            <div className="TermsDate">LAST MODIFIED: FEBRUARY 14, 2024</div>
            <div className="TermsContent">
                <p>
                    <div className="TermsContentHeading">1. General Information</div>
                    <p className="TermsContentInfo">a. this vote is (in its current form) only made for a single event [...], 
                    <br></br>and only accessible via its website.
                    <br></br>b. the top [...] most liked songs will be played together with preselected tracks by the events' admins</p>
                </p>
                <p>
                    <div className="TermsContentHeading">2. Song Voting Rules</div>
                    <p className="TermsContentInfo">a. the song shouldn't include (any) form of harassment/offensive lyrics/slander,
                    <br></br> otherwise, a disqualification  of a track is taken into consideration.
                    <br></br> b. a permanent ban is possible, when violating against the ToS [Terms of Service] 
                    <br></br>  provided by nicodenetworks (nicodenetworks.com) and its subsidiary website nicodegmbh (nicodegmbh.de)
                    </p>
                </p>
                <p>
                    <div className="TermsContentHeading">3. Item Deletion</div>
                    <p className="TermsContentInfo">Votes that have received more than two likes cannot be deleted by the user.
                    <br></br> This limitation is in place to uphold the authenticity of the voting process and prevent manipulation.
                    <br></br>If you encounter any issues or have questions regarding vote management, please contact our support team at info@nicodenetworks.com.
                    <br></br> We are here to assist you and ensure a positive experience for all members of our community.</p>
                </p>
                <p>
                    <div className="TermsContentHeading">4. Intellectual Property</div>
                    <p className="TermsContentInfo">a. All content, including but not limited to text, images, and logos,
                    <br></br> on nicodenetworks.com is the property of Nicode Networks and is protected by copyright laws.
                    <br></br>
                    b. The use of the Spotify API and any related trademarks are subject to Spotify's intellectual property rights.</p>
                </p>
                <p>
                    <div className="TermsContentHeading"></div>
                    <p className="TermsContentInfo"></p>
                </p>
                <p>
                    <div className="TermsContentHeading"></div>
                    <p className="TermsContentInfo"></p>
                </p>
                <p>
                    <div className="TermsContentHeading"></div>
                    <p className="TermsContentInfo"></p>
                </p>
            </div>
            <footer className="TermsContentFooter">Copyright © 2024 Nicolas Pfeifer. All rights reserved.</footer>
        </div>
    )
}

export default Vote;