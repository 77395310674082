import {motion, AnimatePresence} from 'framer-motion';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient, QueryClient, QueryClientProvider, useMutation } from '@tanstack/react-query'
 
function SongDropdownItem(props) {

    const [like, setLike] = useState(props.likes.length);
    const [isLoadingLike, setIsLoadingLike] = useState(false);
    const [liked, setLiked] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

  var likeParams = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({uid: props.uid, reqUserID: props.userID})
  }

    //needs optimistic updates!!!
  //currently only updates liked status optimistically
  const likeQuery = useMutation({
    nextFetchPolicy: 'cache-first',
    mutationKey: ['likeAdd'],
    mutationFn: () => 
      fetch(process.env.REACT_APP_BACKEND_URL + 'api/likes/like', likeParams)
      .then((res) => {
        return res.json()
      }).then((data) => {
        //console.log(data.likes.length);
        setLike(data.likes.length);
        setLiked(data.likes.includes(props.userID));
        setIsLoadingLike(false);
      }),
      onMutate: () => {
        setLiked(true);
        setLike(like + 1);
        setIsLoadingLike(true);
      },
      onError: () => {
        setLiked(false);
        setLike(like);
        setIsLoadingLike(false);
      },
      onSettled: () => {
        //setPrevLike(prevLike+1)
      }
  });

  const dislikeQuery = useMutation({
    mutationFn: () =>
    fetch(process.env.REACT_APP_BACKEND_URL + 'api/likes/unLike', likeParams)
      .then((res) => {
        return res.json()
      }).then((data) => {
        setLike(data.likes.length)
        setLiked(data.likes.includes(props.userID));
        setIsLoadingLike(false);
      }),
      onMutate: () => {
        setLiked(false);
        setLike(like - 1);
        setIsLoadingLike(true);
      },
      onError: () => {
        setLiked(true);
        setLike(like);
        setIsLoadingLike(false);
      },
      onSettled: () => {
        
      }
  });

  useEffect(() =>{
    setLiked(props.likes.includes(props.userID));
  }, [props.userID, props.likes]);

  async function addLike(uid, userID, token){
    likeQuery.mutate();
  }

  async function removeLike(uid, userID, token){
    dislikeQuery.mutate();
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

    return(
        <div>
        <AnimatePresence>
        {
        props.isVisible && (
          <motion.div className='songDropdownActiveWrapper' key="modal" initial={{ height: 0, opacity: 0 }} animate={{ 
            height: (width <= 768) ? "auto" : 200, opacity: 1, 
            transition:{type: "spring", bounce: .3, opacity: {delay: 0.025}, duration: .5}
          }} exit={{ height: 0, opacity: 0 }} transition={{duration: .5, type: "spring", bounce: .3}}>
            <div className='userAvatarDropdownActive'>
              <div className='DAHeading'>ADDED SONG</div>
              <div className='DAuserName'>{props.songName}</div>
              <img className='DAdivider' src='./divider2.svg'></img>
              <div className='DAcontent'>
                <div className='DAlikeFieldBtn' onClick={() => {liked ? removeLike(props.uid, props.userID, props.token) : addLike(props.uid, props.userID, props.token); }}>
                    <motion.div className='DAlikeField'>
                        <motion.img className='PLSongLikeImg' src={liked ? 'liked.svg' : 'like.svg'} alt='likeIcon' width='30px' initial={{ opacity: 0, scale: 0.5 }} 
                        animate={{ opacity: 1, scale: 1 }} transition={{  type: 'spring', duration: 0.8, bounce: .4 }} whileTap={{ scale: 2 }}

                        onClick={() => {liked ? removeLike(props.uid, props.userID, props.token) : addLike(props.uid, props.userID, props.token); }} disabled={isLoadingLike}></motion.img>
                        <span className='DASongLikeCount'>{like}</span>
                    </motion.div>
                    <img className='DAlikeDiv' src='./divider3.svg' alt='divider'/>
                    <div className='DAlikeTxt'>{liked ? "Liked" : "Like"}</div>
                </div>
                <span className='DAcontentItemWrapper'><a href={'https://open.spotify.com/track/' + props.uid} className='DAcontentItem'>Open Song</a></span>
                <span className='DAcontentItemWrapper'><a href={`mailto:info@nicodenetworks.com?subject=[Schulball24] Item Report (${props.songName}, ${props.uid} / ${props.userID})`} className='DAcontentItem'>Report</a></span>
              </div>
            </div>
          </motion.div>

          )
        }
         </AnimatePresence>
         </div>
    )
}

export default SongDropdownItem;