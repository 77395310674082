import "./legal.css"

function Terms() {
    return(
        <div className="Terms">
            <div className="TermsHeadings">
                <div className="TermsHeading">Terms and Conditions</div>
                <div className="TermsSubHeading">nicode networks - nicodenetworks.com</div>
            </div>
            <div className="TermsDate">LAST MODIFIED: FEBRUARY 10, 2024</div>
            <div className="TermsContent">
                <p>
                    <div className="TermsContentHeading">1. Acceptance of Terms</div>
                    <p className="TermsContentInfo">By accessing and using nicodenetworks.com,
                    <br></br> you agree to comply with and be bound by these terms and conditions. 
                    <br></br>
                     If you do not agree with any part of these terms, please do not use our website.</p>
                </p>
                <p>
                    <div className="TermsContentHeading">2. Use of Spotify API</div>
                    <p className="TermsContentInfo">a. Nicode Networks utilizes the Spotify API for search prompts on the website.
                    <br></br>
                    b. You agree to abide by Spotify's Developer Terms of Service and any other relevant policies provided by Spotify.
                    </p>
                </p>
                <p>
                    <div className="TermsContentHeading">3. User Conduct</div>
                    <p className="TermsContentInfo">a. You are responsible for your use of the website and any consequences that may arise from it.
                    <br></br>
                    b. You agree not to engage in any activity that may interfere with the proper functioning <br></br> of the website or compromise its security.</p>
                </p>
                <p>
                    <div className="TermsContentHeading">4. Intellectual Property</div>
                    <p className="TermsContentInfo">a. All content, including but not limited to text, images, and logos,
                    <br></br> on nicodenetworks.com is the property of Nicode Networks and is protected by copyright laws.
                    <br></br>
                    b. The use of the Spotify API and any related trademarks are subject to Spotify's intellectual property rights.</p>
                </p>
                <p>
                    <div className="TermsContentHeading"></div> 
                    <p className="TermsContentInfo"></p>
                </p>
                <p>
                    <div className="TermsContentHeading"></div>
                    <p className="TermsContentInfo"></p>
                </p>
                <p>
                    <div className="TermsContentHeading"></div>
                    <p className="TermsContentInfo"></p>
                </p>
            </div>
            <footer className="TermsContentFooter">Copyright © 2024 Nicolas Pfeifer. All rights reserved.</footer>
        </div>
    )
}

export default Terms;