import "./legal.css"

function FAQ() {
    return(
        <div className="Terms">
            <div className="TermsHeadings">
                <div className="TermsHeading">Frequently Asked Questions</div>
                <div className="TermsSubHeading">nicode networks - nicodenetworks.com</div>
            </div>
            <div className="TermsDate">LAST MODIFIED: FEBRUARY 17, 2024</div>
            <div className="TermsContent">
            <p>
                    <div className="TermsContentHeading">Why can't I use the voting service?</div>
                    <p className="TermsContentInfo">At this time, the use of our voting system is only accessible  to a small range of people<br></br> and made for a single event.</p>
                </p>
                <p>
                    <div className="TermsContentHeading">How do I create votes?</div>
                    <p className="TermsContentInfo">Users can initiate song votes by utilizing the Spotify search functionality.
                    <br></br> Simply enter the song's name or URL, and you're ready to create a vote for others to participate in.</p>
                </p>
                <p>
                    <div className="TermsContentHeading">How does the voting process work?</div>
                    <p className="TermsContentInfo">a. Nicode Networks utilizes the Spotify API for search prompts on the website.
                    <br></br>
                    b. You agree to abide by Spotify's Developer Terms of Service and any other relevant policies provided by Spotify.
                    </p>
                </p>
                <p>
                    <div className="TermsContentHeading">Is it possible to listen to songs without a Spotify account?</div>
                    <p className="TermsContentInfo">Certainly, thanks to the Spotify API and its song preview feature,
                    <br></br> you can enjoy 30-second song previews even without a Spotify account.</p>
                </p>
                <p>
                    <div className="TermsContentHeading">Am I able to report inappropriate  or violating votes?</div>
                    <p className="TermsContentInfo">If you believe a vote does not align with the event or violates guidelines,
                    <br></br> you can formally report it for review. Please send your concerns to info@nicodenetworks.com,
                    <br></br> and we will address the issue promptly.</p>
                </p>
                <p>
                    <div className="TermsContentHeading">Is the Spotify Voting App still under development?</div>
                    <p className="TermsContentInfo">Yes, the project is currently under development as we strive to enhance and optimize the user
                    <br></br> experience. Stay tuned for exciting updates and new features!</p>
                </p>
                <p>
                    <div className="TermsContentHeading">Where can I reach out for additional support or inquiries?</div>
                    <p className="TermsContentInfo">For any further assistance or inquiries, feel free to contact us at
                    <br></br> info@nicodenetworks.com. We're here to help!</p>
                </p>
                <p>
                    <div className="TermsContentHeading">What nicodenetworks is</div>
                    <p className="TermsContentInfo">The Voting App is a frontend project designed for users
                     to create and participate in song votes using the Spotify API.
                    <br></br> It aims to offer an engaging platform for users to collaboratively curate playlists based on popular votes.</p>
                </p>
                <p>
                    <div className="TermsContentHeading"></div>
                    <p className="TermsContentInfo"></p>
                </p>
                <p>
                    <div className="TermsContentHeading"></div>
                    <p className="TermsContentInfo"></p>
                </p>
                <p>
                    <div className="TermsContentHeading"></div>
                    <p className="TermsContentInfo"></p>
                </p>
            </div>
            <footer className="TermsContentFooter">Copyright © 2024 Nicolas Pfeifer. All rights reserved.</footer>
        </div>
    )
}

export default FAQ;