function Cookies() {
    return(
        <div className="Terms">
        <div className="TermsHeadings">
            <div className="TermsHeading">Cookies Notice</div>
            <div className="TermsSubHeading">nicode networks - nicodenetworks.com</div>
        </div>
        <div className="TermsDate">LAST MODIFIED: FEBURARY 11, 2024</div>
        <div className="TermsContent">
            <p>
                <div className="TermsContentHeading">1. What Are Cookies</div>
                <p className="TermsContentInfo">As is common practice with almost all professional websites, this site uses cookies,
                <br></br> which are tiny files that are downloaded to your computer, to improve your experience.
                <br></br> This page describes what information they gather,
                 how we use it and why we sometimes need to store these cookies.
                <br></br> We will also share how you can prevent these cookies from being stored 
                <br></br>however, this may downgrade or 'break' certain elements of the sites' functionality.</p>
            </p>
            <p>
                <div className="TermsContentHeading">2. Use of Cookies</div>
                <p className="TermsContentInfo">Cookies are used on this website to <br></br>
                a. process the user login <br></br>
                b. store information, like customized designs and more
                </p>
            </p>
            <p>
                <div className="TermsContentHeading">3. User Conduct</div>
                <p className="TermsContentInfo">a. You are responsible for your use of the website and any consequences that may arise from it.
                <br></br>
                b. You agree not to engage in any activity that may interfere with the proper functioning of the website or compromise its security.</p>
            </p>
            <p>
                <div className="TermsContentHeading">4. Intellectual Property</div>
                <p className="TermsContentInfo">a. All content, including but not limited to text, images, and logos, on nicodenetworks.com is the property of Nicode Networks and is protected by copyright laws.
                <br></br>
                b. The use of the Spotify API and any related trademarks are subject to Spotify's intellectual property rights.</p>
            </p>
            <p>
                <div className="TermsContentHeading"></div>
                <p className="TermsContentInfo"></p>
            </p>
            <p>
                <div className="TermsContentHeading"></div>
                <p className="TermsContentInfo"></p>
            </p>
            <p>
                <div className="TermsContentHeading"></div>
                <p className="TermsContentInfo"></p>
            </p>
        </div>
        <footer className="TermsContentFooter">Copyright © 2024 Nicolas Pfeifer. All rights reserved.</footer>
    </div>
    )
}

export default Cookies;