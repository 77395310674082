import "./Home.css"
import { motion, AnimatePresence } from "framer-motion"

export default function Home(){

    return (
        <div className="Home">
            <div className="HomeHeader">
                <span className="HMHEaderProjectName">nicode networks</span>
                <span className="HMHeaderDemoName">Schulball'24</span>
           </div>
           <div className="HomeHeadingContainerWrapper">
            <div className="HomeHeadingContainer">
                    <div className="HomeHeading">Spotify Voting</div>
                    <div className="HomeSubheading">Features</div>
                    <ul className="HMFeaturesContent">
                        <li className="HMFeaturesList"><b>Create Votes:</b><br></br>
                        <div className="HMFeaturesListDescription"> Users can initiate song votes through Spotify search, using a songs name or url.</div></li>
                        <li className="HMFeaturesList"><b>Voting:</b><br></br>
                        <div className="HMFeaturesListDescription"> Users can express their preferences by liking votes created by others, influencing the overall popularity of a song.</div></li>
                        <li className="HMFeaturesList"><b>Secure Sign-In:</b><br></br>
                        <div className="HMFeaturesListDescription"> Utilizing Keycloak, the app ensures secure user authentication for a personalized experience.</div></li>
                        <li className="HMFeaturesList"><b>Vote Management:</b><br></br>
                        <div className="HMFeaturesListDescription"> Users have the ability to delete their votes, particularly those with less than two likes,<br></br> to prevent fraudulent activities and maintain the integrity of the voting system.</div></li>
                        <li className="HMFeaturesList"><b>30s Song Previews:</b><br></br>
                        <div className="HMFeaturesListDescription"> Thanks to the use of Spotify API and its' song preview feature, you can listen votes without a spotify account</div></li>
                        <li className="HMFeaturesList"><b>Vote Report:</b><br></br>
                        <div className="HMFeaturesListDescription"> If a user considers that a vote does not align with the event or violates guidelines,<br></br>
                        there's an option to formally report it for review {"->"} <b>info@nicodenetworks.com.</b></div></li>
                    </ul>
                    <div className="HMBtns">
                        <a className="HMInquire" href="mailto:info@nicodenetworks.com?subject=Spotify Song Vote Inquire">Inquire</a>
                        <a className="HMDemoProject" href="/schulball">Demo</a>
                    </div>
            </div>
           </div>
  
           <motion.div className="HMThumbnailConatiner" initial={{ opacity: 0, x:300, scale: .5 }}
            whileInView={{ opacity: 1, x: 0, scale:1 }}
            viewport={{ once: false }}
            transition={{duration: .2, opacity: {duration: .5}}}>
            <img className="HMThumbnail" src="./voting-thumbnail.png" alt="website-thumbnail"/>
           </motion.div>
        </div>
    )
}