import MainPlaylistHandler from '../../components/MainPlaylistHandler';
import MainPlaylistReadOnly from '../../components/MainPlaylist_read-only';

function Login(){
    const queryParameters = new URLSearchParams(window.location.search);
    const action = queryParameters.get('v');

    if(action === '1'){
     //read-only page -> ...?pl=...&v=1
        return(
            <MainPlaylistReadOnly></MainPlaylistReadOnly>
        )

    } else {
        //main page with required login
        return(
            <MainPlaylistHandler></MainPlaylistHandler>
        )
    }
}

export default Login;