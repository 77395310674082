import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/playlistInvitations/Login/inviteLogin';
import MainPlaylist from './pages/MainPlaylist/MainPlaylist';
import NotFound from './pages/NotFound';
import Invite from './pages/inviteHandler';
import UrlHandler from './pages/urlHandler';
import TermsConditions from './pages/Legal/Terms-Conditions';
import Cookies from './pages/Legal/Cookies';
import Privacy from './pages/Legal/Privacy';
import VoteInfo from './pages/Legal/Vote';
import FAQ from './pages/Legal/FAQ';
import EndedPage from './pages/ended/ended';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, InputGroup, FormControl, Button, Row, Card, DropdownButton, Alert} from "react-bootstrap"
import { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import * as buffer from "buffer";
window.Buffer = buffer.Buffer;

function App() {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/playlist' element={<EndedPage />} /> {/*Acive = MainPlaylist*/}
          <Route path='/Schulball24' element={<EndedPage />} /> {/*Acive = UrlHandler*/}
          <Route path='/Schulball' element={<EndedPage />} /> {/*Acive = UrlHandler*/}
          <Route path='/freddyzugang' element={<MainPlaylist />} />
          <Route path='/invite' element={<Invite />} />
          <Route path='/profile=create' element={<newProfile />} />
          <Route path='/legal/terms-and-conditions' element={<TermsConditions />} />
          <Route path='/legal/cookies' element={<Cookies></Cookies>} />
          <Route path='/legal/privacy' element={<Privacy></Privacy>} />
          <Route path='/guidelines' element={<VoteInfo></VoteInfo>} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
