import { useEffect, useState, useRef } from "react";
import Keycloak from "keycloak-js";

const useAuthReq = () => {
    // Access to Page only possible with Keycloak Account
    const alreadyRun = useRef(false);
    const [token, setToken] = useState(null);
    const [userID, setUserID] = useState(null);
    const [isLogin, setLogin] = useState(false);

    useEffect(() => {
        /*
        --------------BUG--------------
        When using this hook to limit access to 
        a page (in this case spotifyApi and it's main page), 
        it may reload constantly. [currently only on Firefox!]
        ------------REASON--------------
        Firefox and other browsers block cookies automatically
        -> Cookies provided by keycloak are blocked!
        => Page tries tp reload every second
        --------------------------------
             UNSTABlE, NEED FIX!
        */

        if(alreadyRun.current) return; //React.StricktMode -> effect hook is run 2x
        alreadyRun.current = true;

        const client = new Keycloak({
            url: process.env.REACT_APP_KEYCLOAK_URL,
            realm: process.env.REACT_APP_KEYCLOAK_REALM,
            clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
        });

        client.init({onLoad: 'login-required', checkLoginIframe: false})
            .then((res => {
                setLogin(res);
                setToken(client.token);
                setUserID(client.subject); //Keycloak userID. May be uneccessary, could be extracted from token via public key and processed via backend
            }));
    }, [])

    return [isLogin, token, userID];
};

export default useAuthReq;